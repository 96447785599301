<template>
  <div v-if="employeesSalaryData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th rowspan="2">#</th>
            <th colspan="4">{{ $t("Employee.modelName") }}</th>
            <th rowspan="2">{{ $t("SalaryInquiries.totalSalary") }}</th>
            <th rowspan="2">{{ $t("SalaryInquiries.insuranceStatus") }}</th>
            <th rowspan="2">{{ $t("SalaryInquiries.insuranceAmount") }}</th>
            <th rowspan="2">{{ $t("SalaryInquiries.totalEmployeeGrants") }}</th>
            <th rowspan="2">
              {{ $t("SalaryInquiries.totalAllowancesMonth") }}
            </th>
            <th rowspan="2">{{ $t("SalaryInquiries.totalMovementSalary") }}</th>
            <th rowspan="2">{{ $t("SalaryInquiries.totalWorkTimesPrice") }}</th>
            <th rowspan="2">{{ $t("info") }}</th>
          </tr>
          <tr>
            <th>{{ $t("Employee.code") }}</th>
            <th class="cell-lg">{{ $t("Employee.name") }}</th>
            <th>{{ $t("Employee.phone") }}</th>
            <th>{{ $t("Employee.jobInContract") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(SalaryInquiry, index) in employeesSalaryData"
            :key="index"
          >
            <td>{{ ++index + pagination.currentIndex }}</td>
            <td>{{ isDataExist(SalaryInquiry.employeeCode) }}</td>
            <td class="cell-with-image">
              <img
                class="item-img-table"
                :src="BASE_URL + SalaryInquiry.employeeImagePath"
                :onerror="`this.src='${DEFAULT_IMG}'`"
              />
              <span>{{ isDataExist(SalaryInquiry.employeeNameCurrent) }}</span>
            </td>
            <td>{{ isDataExist(SalaryInquiry.employeePhoneWithCC) }}</td>
            <td>{{ isDataExist(SalaryInquiry.employeeJobNameCurrent) }}</td>
            <td>{{ moneyFormat(SalaryInquiry.totalSalary) }}</td>
            <td>
              {{
                isDataExist(
                  SalaryInquiry.salaryCalculationDetails
                    .insuranceStatusNameCurrent
                )
              }}
            </td>
            <td>
              {{
                moneyFormat(
                  SalaryInquiry.salaryCalculationDetails.insuranceAmount
                )
              }}
            </td>
            <td>
              {{
                moneyFormat(
                  SalaryInquiry.salaryCalculationDetails.totalEmployeeGrants
                )
              }}
            </td>
            <td>
              {{
                moneyFormat(
                  SalaryInquiry.salaryCalculationDetails.totalAllowancesMonth
                )
              }}
            </td>
            <td>
              {{
                moneyFormat(
                  SalaryInquiry.salaryCalculationDetails.totalMovementSalary
                )
              }}
            </td>
            <td>
              {{
                moneyFormat(
                  SalaryInquiry.salaryCalculationDetails.totalWorkTimesPrice
                )
              }}
            </td>
            <td>
              <button
                v-b-modal.SalaryInquiryInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setSalaryInquiry(SalaryInquiry)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import DEFAULT_IMG from "@/assets/images/user.jpg";
import { BASE_URL } from "../../../utils/constants";
import { isDataExist, moneyFormat } from "../../../utils/functions";
import privilegeMixin from "@/utils/privilege-mixin";

export default {
  mixins: [privilegeMixin],
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      DEFAULT_IMG,
      BASE_URL,
    };
  },
  props: ["employeesSalaryData", "pagination"],
  methods: {
    setSalaryInquiry(data) {
      this.$emit("setSalaryInquiry", data);
    },
    isDataExist,
    moneyFormat,
  },
  computed: {},
  async created() {},
};
</script>
