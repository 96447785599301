export default class AllEmployeesStatisticsSalary {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.totalEmployeesAllowancesMonth = 0;
    this.totalEmployeesNetSalary = 0;
    this.totalEmployeesMovementSalary = 0;
    this.totalEmployeesAllowance = 0;
    this.totalEmployeesWorkTimePrice = 0;
    this.totalEmployeesLateAttendaceTimePrice = 0;
    this.totalEmployeesEarlyDepartureTimePrice = 0;
    this.totalEmployeesLostTimePrice = 0;
    this.totalEmployeesOverTimePrice = 0;
    this.totalEmployeesOfficialHolidayTimePrice = 0;
    this.totalEmployeesAbsenceTimePrice = 0;
    this.totalEmployeesUnknownWorkingTimePrice = 0;
  }
  fillData(data) {
    this.totalEmployeesAllowancesMonth = data.totalEmployeesAllowancesMonth;
    this.totalEmployeesNetSalary = data.totalEmployeesNetSalary;
    this.totalEmployeesMovementSalary = data.totalEmployeesMovementSalary;
    this.totalEmployeesAllowance = data.totalEmployeesAllowance;
    this.totalEmployeesWorkTimePrice = data.totalEmployeesWorkTimePrice;
    this.totalEmployeesLateAttendaceTimePrice =
      data.totalEmployeesLateAttendaceTimePrice;
    this.totalEmployeesEarlyDepartureTimePrice =
      data.totalEmployeesEarlyDepartureTimePrice;
    this.totalEmployeesLostTimePrice = data.totalEmployeesLostTimePrice;
    this.totalEmployeesOverTimePrice = data.totalEmployeesOverTimePrice;
    this.totalEmployeesOfficialHolidayTimePrice =
      data.totalEmployeesOfficialHolidayTimePrice;
    this.totalEmployeesAbsenceTimePrice = data.totalEmployeesAbsenceTimePrice;
    this.totalEmployeesUnknownWorkingTimePrice =
      data.totalEmployeesUnknownWorkingTimePrice;
  }
}
