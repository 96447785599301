<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <div
      v-if="
        salaryInquiries.employeesSalaryData != undefined &&
        salaryInquiries.employeesSalaryData.length != 0
      "
    >
      <SalaryInquiriesTable
        v-on:setSalaryInquiry="salaryInquiries.salaryInquiry.fillData($event)"
        :employeesSalaryData="salaryInquiries.employeesSalaryData"
        :pagination="salaryInquiries.pagination"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="salaryInquiries.pagination"
        v-on:changePage="changePage"
      />
      <!-- <SalaryInquiryInfo :salaryInquiry="salaryInquiries.salaryInquiry" /> -->
      <!-- <SalaryInquiryCancel
        :salaryInquiry="salaryInquiries.salaryInquiry"
        v-on:refresh="getEmployeesStatisticsSalary()"
      /> -->
      <!-- <SalaryInquirySettlement
        :salaryInquiry="salaryInquiries.salaryInquiry"
        v-on:refresh="getEmployeesStatisticsSalary()"
      /> -->
    </div>

    <!-- <SalaryInquiryBtns
      :theFilterData="salaryInquiries.filterData"
      v-on:search="search($event)"
    /> -->
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import SalaryInquiriesTable from "@/components/finance/salaryInquiries/SalaryInquiriesTable.vue";
// import SalaryInquiryInfo from "@/components/finance/salaryInquiries/SalaryInquiryInfo.vue";
// import SalaryInquiryCancel from "@/components/finance/salaryInquiries/SalaryInquiryCancel.vue";
// import SalaryInquirySettlement from "@/components/finance/salaryInquiries/SalaryInquirySettlement.vue";
// import SalaryInquiryBtns from "@/components/finance/salaryInquiries/SalaryInquiryBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import SalaryInquiries from "@/models/finance/salaryInquiries/SalaryInquiries";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    SalaryInquiriesTable,
    // SalaryInquiryInfo,
    // SalaryInquiryCancel,
    // SalaryInquirySettlement,
    // SalaryInquiryBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      salaryInquiries: new SalaryInquiries(),
    };
  },
  methods: {
    changePage(page) {
      this.salaryInquiries.pagination.selfPage = page;
      this.getEmployeesStatisticsSalary();
    },
    search(data) {
      this.salaryInquiries.filterData.fillData(data);
      this.salaryInquiries.employeesSalaryData = [];
      this.getEmployeesStatisticsSalary();
    },
    async getEmployeesStatisticsSalary() {
      this.isLoading = true;
      try {
        const response =
          await this.salaryInquiries.salaryInquiry.getEmployeesStatisticsSalary(
            this.language,
            this.userAuthorizeToken,
            this.salaryInquiries.pagination,
            this.salaryInquiries.filterData
          );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.salaryInquiries.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.salaryInquiries.employeesSalaryData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.salaryInquiries.employeesSalaryData = [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.salaryInquiries.employeesSalaryData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.salaryInquiries.employeesSalaryData = [];
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    this.getEmployeesStatisticsSalary();
  },
};
</script>
