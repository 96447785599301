import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "@/models/general/PaginationData";
import SalaryInquiriesFilter from "./SalaryInquiriesFilter";

export default class SalaryInquiry {
  // constructor() {}

  async getEmployeesStatisticsSalary(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new SalaryInquiriesFilter()
  ) {
    try {
      let response = await axios.get(
        `/SalaryInquiry/GetEmployeesStatisticsSalary?language=${language}&userAuthorizeToken=${userAuthorizeToken}&cityArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&year=${filterData.year}&month=${filterData.month}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }
}
