import PaginationData from "@/models/general/PaginationData";
import SalaryInquiry from "./SalaryInquiry";
import SalaryInquiriesFilter from "./SalaryInquiriesFilter";
import AllEmployeesStatisticsSalary from "./AllEmployeesStatisticsSalary";

export default class SalaryInquiries {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.allEmployeesStatisticsSalary = new AllEmployeesStatisticsSalary();
    this.pagination = new PaginationData();
    this.employeesSalaryData = [];
    this.salaryInquiry = new SalaryInquiry();
    this.filterData = new SalaryInquiriesFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.allEmployeesStatisticsSalary.fillData(
      data.allEmployeesStatisticsSalary
    );
    this.pagination.fillData(data.employeesSalaryPagination);
    this.employeesSalaryData =
      data.employeesSalaryPagination.employeesSalaryData;
  }
}
